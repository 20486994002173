<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Pagar Boleto</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <div class="card">
          <div class="card-body" v-if="!docsOk">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-md">Antes de pagar Boleto, é necessário verificar a conta, completando o seu cadastro.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 text-center">
                <router-link to="/pages/user" class="btn btn-primary">
                  <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <input type="hidden" id="authentication-copy" :value="authentication">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-sm-10">
                    <base-input label="Linha Digitável"
                                name="Linha Digitável"
                                type="text"
                                class="billet"
                                aria-placeholder="Informe a linha digitável"
                                v-model="payment.codBillet"
                                v-mask="['#####.##### #####.###### #####.###### # ##############', '############ ############ ############ ############']"/>
                  </div>
                  <div class="col-sm-2">
                    <base-input label=" " class="billet">
                      <button type="button" class="btn btn-primary" @click="preparePayment">
                        <span class="btn-label"><i class="fas fa-search"></i></span>
                      </button>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <card v-if="showButton">
                    <div class="col-md-12">
                      <div class="row" v-if="showButton">
                        <div class="col-md-4">
                          <base-input label="Valor"
                                      placeholder="0,00"
                                      type="text"
                                      name="Valor"
                                      disabled
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.value"
                                      :key="payment.value">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>
                        <div class="col-md-4">
                          <base-input label="Data de Vencimento" type="text" disabled v-model="payment.dueDate"
                                      :key="payment.dueDate"/>
                        </div>
                        <div class="col-md-4">
                          <base-input label="Data de Pagamento" type="text" disabled v-model="payment.paymentDate"
                                      :key="payment.paymentDate"/>
                        </div>
                      </div>
                      <div class="row" v-if="showButton && payment.chargedValue !== '0,00'">
                        <div class="col-md-4">
                          <base-input label="Valor Cobrado"
                                      placeholder="0,00"
                                      type="text"
                                      name="Valor Cobrado"
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.chargedValue"
                                      disabled
                                      :key="payment.chargedValue">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>
                      </div>
                      <div class="row" v-if="showButton && payment.chargedValue !== '0,00'">
                        <div class="col-md-3">
                          <base-input label="Desconto"
                                      placeholder="0,00"
                                      type="text"
                                      name="Desconto"
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.discount"
                                      disabled
                                      :key="payment.discount">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>

                        <div class="col-md-3">
                          <base-input label="Juros"
                                      placeholder="0,00"
                                      type="text"
                                      name="Juros"
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.defaultInterest"
                                      disabled
                                      :key="payment.defaultInterest">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>

                        <div class="col-md-3">
                          <base-input label="Outras Deduções"
                                      placeholder="0,00"
                                      type="text"
                                      name="Outras Deduções"
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.otherDeductions"
                                      disabled
                                      :key="payment.otherDeductions">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>

                        <div class="col-md-3">
                          <base-input label="Outras Adições"
                                      placeholder="0,00"
                                      type="text"
                                      name="Outras Adições"
                                      :rules="{max:14}"
                                      v-money="money"
                                      v-model="payment.otherAdditions"
                                      disabled
                                      :key="payment.otherAdditions">
                            <span slot="prepend">R$</span>
                          </base-input>
                        </div>
                      </div>
                      <div class="row" v-if="showButton">
                        <div class="col-md-8">
                          <base-input label="Nome do Beneficiário" type="text" disabled v-model="payment.destinationName"
                                      :key="payment.destinationName"/>
                        </div>
                        <div class="col-md-4">
                          <base-input label="CPF/CNPJ do Beneficiário" type="text" disabled
                                      v-model="payment.destinationSocialNumber" :key="payment.destinationSocialNumber"/>
                        </div>
                      </div>
                      <div class="row" v-if="showButton">
                        <div class="col-md-12">
                          <base-input label="Nome do Pagador" type="text" disabled v-model="payment.payerName"
                                      :key="payment.payerName"/>
                        </div>
                      </div>
                      <div class="row" v-if="showButton">
                        <div class="col-md-12">
                          <base-input label="Banco Emissor" type="text" disabled v-model="payment.emitterBankName"
                                      :key="payment.emitterBankName"/>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="row mr-5" v-if="showButton">
                  <card class="col-md-12 text-center pr-5">
                      <label class="control-label">Descrição</label>
                      <input class="form-control" v-model="payment.description" maxlength="20"/>
                      <button type="button" class="btn btn-primary m-3"
                              v-bind:disabled="payingBankSlip" @click="doPayment">
                        <span class="btn-label"><i slot="label" class="fas fa-file-upload"></i></span> Realizar Pagamento
                      </button>
                  </card>
                </div>
              </div>
              <div class="col-md-6">
                <card header-classes="bg-transparent" body-classes="px-lg-7" class="card-pricing border-0 text-center mb-4">
                  <!--Header-->
                  <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Pague Títulos Bancários pela
                    Olimpo Pay!</h4>

                  <!--Body-->
                  <div class="display-2">R$ 0,00</div>
                  <span class=" text-muted">por pagamento</span>
                  <ul class="list-unstyled my-4">
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Pague qualquer título bancário.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">O horário permitido para esta operação é de segunda à sexta, de <b>05:00</b> à <b>20:00</b>.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Apenas títulos não vencidos são aceitos.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span
                            class="pl-2 text-sm">A efetivação do pagamento é realizada dentro de poucos minutos.</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import DzsTevTransferDetails from "@/components/UIComponents/Dzs/Transfer/DzsTevTransferDetails"
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "@/util/core.utils"
  import {postWs} from "@/ws.service"
  import {formatDate} from "@/util/date.utils"

  export default {
    components: {
      DzsTevTransferDetails,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        docsOk: false,
        accountBalance: '0,00',
        payingBankSlip: false,
        showButton: false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        authentication: '',
        validateIntervalLoop: null,
        payment: {
          value: '',
          description: '',
          dueDate: '',
          codBillet: '',
          paymentDate: formatDate(Date(), "DD/MM/YYYY"),
          emitterBankName: '',
          destinationName: '',
          destinationSocialNumber: '',
          payerName: '',
          chargedValue: '',
          discount: '',
          defaultInterest: '',
          otherDeductions: '',
          otherAdditions: '',
        },
      };
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }

      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
    },

    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      copyAuthenticationCode() {
        let authenticationCopy = document.querySelector('#authentication-copy')
        authenticationCopy.setAttribute('type', 'text')
        authenticationCopy.select()

        try {
          let successful = document.execCommand('copy');
          let msg = successful ? 'successful' : 'unsuccessful';
          if (msg == 'successful') {
            this.$swal({
              text: 'Código do Comprovante copiado!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'success'
            })
          } else {
            this.$swal({
              text: 'Erro ao copiar o Código do Comprovante!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          }
        } catch (err) {
          this.$swal({
            text: 'Erro ao copiar o Código do Comprovante!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        }

        /* unselect the range */
        authenticationCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      populateFields(response) {
        this.payment.value = toMoney(response.data.value);
        this.payment.codBillet = response.data.typeableLine;
        this.payment.dueDate = response.data.dueDate;
        this.payment.emitterBankName = "(" + response.data.emitterBankCode + ") " + response.data.emitterBankName;
        this.payment.destinationName = response.data.destinationName;
        this.payment.destinationSocialNumber = response.data.destinationSocialNumber;
        this.payment.payerName = response.data.payerName;
        this.payment.chargedValue = toMoney(response.data.chargedValue);
        this.payment.discount = toMoney(response.data.discount);
        this.payment.defaultInterest = toMoney(response.data.defaultInterest);
        this.payment.otherDeductions = toMoney(response.data.otherDeductions);
        this.payment.otherAdditions = toMoney(response.data.otherAdditions);

        this.showButton = true;

        if (this.payment.value === '0,00' && this.payment.chargedValue !== '0,00') {
          this.payment.value = this.payment.chargedValue;
        }
      },
      preparePayment() {
        this.clearFields(false);

        if (this.payment.codBillet == null || this.payment.codBillet === '') {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Linha Digitável é obrigatório!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }

        postWs("/bankslip/info?typeableLine=" + this.payment.codBillet,
          true, null, null,
          (response) => {
            this.populateFields(response);
          }, (error) => {
            let text = null;
            if (error.response.data.response === 'OPERATION_EXCEEDS_TIME_LIMIT') {
              text = 'Faixa de horário para a operação foi excedido.'
            } else if (error.response.data.response === 'EXPIRED_BANKSLIP_NOT_ALLOWED') {
              text = 'Não é permitido pagamento de boleto vencido'
            } else if (error.response.data.response === 'OPERATION_BLOCKED_ON_WEEKENDS') {
              text = 'Operação não permitida aos finais de semana'
            } else if (error.response.data.response === 'BANKSLIP_DUP_OP_WRITE_OFF_NO_PART_PAY') {
              text = 'Baixa operacional em duplicidade para título que não permite pagamento parcial.'
            } else if (error.response.data.response === 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response === 'BLOCKED_OUT_TRANSACTIONS' ||
              error.response.data.response === 'EMITTER_BANK_NOT_FOUND') {
              text = 'Banco emissor não encontrado'
            } else if (error.response.status === 422 && error.response.data.response === 'TYPEABLE_LINE_INVALID') {
              text = 'Linha digitável inválida'
            } else if (error.response.data.response === 'BANKSLIP_GET_INFO_FAIL' && error.response.data.status === 'ALREADY_PAYED') {
              text = 'Este título já foi pago'
            } else if (error.response.data.response === 'BANKSLIP_GET_INFO_FAIL' && error.response.data.status === 'FAIL') {
              if (isNullOrEmpty(error.response.data.errorMessage)) {
                text = 'Falha ao obter informações sobre o título'
              } else {
                text = error.response.data.errorMessage;
              }
            }
            if (!isNullOrEmpty(text)) {
              this.$swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            } else {
              this.$swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: false,
                timer: 5000,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                icon: 'error'
              })
            }
          })
      },
      doPayment() {
        this.payingBankSlip = true
        if (moneyToFloat(this.payment.value) > moneyToFloat(this.accountBalance)) {
          this.$swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.payingBankSlip = false
          return
        } else if (moneyToFloat(this.payment.value) <= 0) {
          this.$swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.payingBankSlip = false
          return
        }

        this.$swal({
          title: 'Confirma a transação ?',
          html: "LINHA DIGITÁVEL: <b>" + this.payment.codBillet + "</b> <br/>" +
            "VALOR DA COBRANÇA: <b>R$ " + (this.payment.chargedValue != '0,00' ? this.payment.chargedValue : this.payment.value) + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callPaymentWs()
          } else {
            this.payingBankSlip = false
          }
        })
      },
      callPaymentWs() {
        postWs("/bankslip/pay",
          true,
          null,
          {
            typeableLine: this.payment.codBillet,
            description: this.payment.description
          },
          (response) => {
            this.payingBankSlip = false
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.response === 'OK') {
              this.success(response.data.authentication)
              return
            }
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          }, (error) => {
            this.payingBankSlip = false
            if (error == null) {
              return
            }

            let text = null;
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.status == 412) {
              return;
            }
            if (!isNullOrEmpty(text)) {
              this.$swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            } else {
              this.$swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: false,
                timer: 5000,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                icon: 'error'
              })
            }
          },
          true)
      },
      clearFields(clearCodBillet) {
        let cdBillet = this.payment.codBillet;

        this.payment = {
          value: '',
          description: '',
          dueDate: '',
          codBillet: '',
          paymentDate: formatDate(Date(), "DD/MM/YYYY"),
          emitterBankName: '',
          destinationName: '',
          destinationSocialNumber: '',
          payerName: '',
          chargedValue: '',
          discount: '',
          defaultInterest: '',
          otherDeductions: '',
          otherAdditions: ''
        }

        if (!clearCodBillet) {
          this.payment.codBillet = cdBillet;
        }
      },
      success(authentication) {
        this.authentication = authentication
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = toMoney(moneyToFloat(this.accountBalance) - moneyToFloat(this.payment.value))
        localStorage.setItem('accountBalance', this.accountBalance)
        this.clearFields(true);
        this.$swal({
          title: 'Sucesso!',
          html: '<p>Código do Comprovante:<br>' + this.authentication + '</p>',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-info btn-fill',
            cancelButton: 'btn btn-success btn-fill'
          },
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Copiar Código',
          cancelButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.copyAuthenticationCode()
          }
        })
      },
    },
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  .billet {
    font-size: 25px;
    border-bottom: 2px solid;
    border-top: 0;
  }

  .form-control:disabled {
    background-color: #fff !important;
  }
</style>
